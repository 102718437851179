/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2017 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */

//// CONTROLLER ////
export class ConfigureChannelMappingDialogController {
    // Public bindable properties
    public currentConfig: any;
    public mediaFormat: any;
    public channelMappings: any;
    public channelCount: any;
    public channelAssociativeObject: any;
    public audioChannels: any;

    // Injectable values
    static get $inject() {
        return [
            '$mdDialog',
            '$q',
            '$scope',
            '$timeout',
            'addelivery.channels.constants',
            'AudioChannelResourceFactory',
            'loginService',
            'config',
            'format',
        ];
    }

    constructor(
        public $mdDialog: any,
        public $q: any,
        public $scope: any,
        public $timeout: any,
        public CHANNEL_CONST: any,
        public AudioChannelResourceFactory: any,
        public loginService: any,
        public config: any,
        public format: any
    ) {
        var vm = this;

        /* BINDABLE : DATA */
        vm.currentConfig = config;
        vm.mediaFormat = format;
        vm.channelMappings =
            vm.currentConfig && vm.currentConfig.AudioChannelMappings
                ? vm.currentConfig.AudioChannelMappings
                : [];
        vm.channelCount = 0;
        vm.channelAssociativeObject = {};

        /* EVENTS */
        //Register any event listeners
    }

    /* IMPLEMENTATION : BINDABLE */
    cancel() {
        let vm = this;
        vm.$mdDialog.cancel();
    }

    save() {
        let vm = this;
        vm.currentConfig.AudioChannelMappings = vm.channelMappings;
        vm.$mdDialog.hide(vm.channelMappings);
    }

    /* IMPLEMENTATION : PRIVATE */
    // All private methods should start with '_' in order to distinguish them
    _setUpChannels = () => {
        let vm = this;

        if (vm.currentConfig && vm.mediaFormat) {
            // calculate how many channels we should have.
            if (vm.mediaFormat.dolbyEFlag) {
                vm.channelCount =
                    (vm.mediaFormat.wav1stExtractionTemplate
                        ? vm.mediaFormat.wav1stExtractionTemplate.channelCount
                        : 0) +
                    (vm.mediaFormat.wav2ndExtractionTemplate
                        ? vm.mediaFormat.wav2ndExtractionTemplate.channelCount
                        : 0);
            } else if (
                vm.mediaFormat.vantageWorkflowName != null &&
                vm.mediaFormat.vantageWorkflowName.length > 0
            ) {
                // COM-8891 - if Vantage, use a hard-coded channel count for HD/SD
                vm.channelCount = 16;
            } else {
                vm.channelCount =
                    vm.mediaFormat.assetContentTemplate &&
                    vm.mediaFormat.assetContentTemplate.audioChannelCount
                        ? vm.mediaFormat.assetContentTemplate.audioChannelCount
                        : 0;
            }

            vm.channelMappings.sort(function (a:any, b:any) {
                return a.channelNumber - b.channelNumber;
            });
            if (vm.channelMappings.length === 0 || vm.channelMappings.length != vm.channelCount) {
                // create new mappings.  If the count doesn't match the current length, then
                // they've chosen a new profile with different mapping requirements.
                vm.channelMappings = [];
                while (vm.channelMappings.length < vm.channelCount) {
                    vm.channelMappings.push({
                        videoFormatProfileId: parseInt(vm.currentConfig.id, 10),
                        channelNumber: vm.channelMappings.length + 1,
                    });
                }
                vm._setDefaultChannels();
            }
        } else {
            // we shouldn't be here, GTFO
            vm.$mdDialog.cancel();
        }
    };

    _setDefaultChannels = () => {
        let vm = this;

        switch (vm.channelMappings.length) {
            case 2:
                vm.channelMappings[0].audioChannelId =
                    vm.channelAssociativeObject[vm.CHANNEL_CONST.MAPPING_STEREO_LEFT];
                vm.channelMappings[1].audioChannelId =
                    vm.channelAssociativeObject[vm.CHANNEL_CONST.MAPPING_STEREO_RIGHT];
                break;
            case 4:
                vm.channelMappings[0].audioChannelId =
                    vm.channelAssociativeObject[vm.CHANNEL_CONST.MAPPING_STEREO_LEFT];
                vm.channelMappings[1].audioChannelId =
                    vm.channelAssociativeObject[vm.CHANNEL_CONST.MAPPING_STEREO_RIGHT];
                vm.channelMappings[2].audioChannelId =
                    vm.channelAssociativeObject[vm.CHANNEL_CONST.MAPPING_SAP_SILENCE];
                vm.channelMappings[3].audioChannelId =
                    vm.channelAssociativeObject[vm.CHANNEL_CONST.MAPPING_VDS_SILENCE];
                break;
            case 6:
                vm.channelMappings[0].audioChannelId =
                    vm.channelAssociativeObject[vm.CHANNEL_CONST.MAPPING_ORG_FRONT_LEFT];
                vm.channelMappings[1].audioChannelId =
                    vm.channelAssociativeObject[vm.CHANNEL_CONST.MAPPING_ORG_FRONT_RIGHT];
                vm.channelMappings[2].audioChannelId =
                    vm.channelAssociativeObject[vm.CHANNEL_CONST.MAPPING_ORG_CENTER];
                vm.channelMappings[3].audioChannelId =
                    vm.channelAssociativeObject[vm.CHANNEL_CONST.MAPPING_ORG_LFE];
                vm.channelMappings[4].audioChannelId =
                    vm.channelAssociativeObject[vm.CHANNEL_CONST.MAPPING_ORG_SURROUND_LEFT];
                vm.channelMappings[5].audioChannelId =
                    vm.channelAssociativeObject[vm.CHANNEL_CONST.MAPPING_ORG_SURROUND_RIGHT];
                break;
            case 8:
                vm.channelMappings[0].audioChannelId =
                    vm.channelAssociativeObject[vm.CHANNEL_CONST.MAPPING_ORG_FRONT_LEFT];
                vm.channelMappings[1].audioChannelId =
                    vm.channelAssociativeObject[vm.CHANNEL_CONST.MAPPING_ORG_FRONT_RIGHT];
                vm.channelMappings[2].audioChannelId =
                    vm.channelAssociativeObject[vm.CHANNEL_CONST.MAPPING_ORG_CENTER];
                vm.channelMappings[3].audioChannelId =
                    vm.channelAssociativeObject[vm.CHANNEL_CONST.MAPPING_ORG_LFE];
                vm.channelMappings[4].audioChannelId =
                    vm.channelAssociativeObject[vm.CHANNEL_CONST.MAPPING_ORG_SURROUND_LEFT];
                vm.channelMappings[5].audioChannelId =
                    vm.channelAssociativeObject[vm.CHANNEL_CONST.MAPPING_ORG_SURROUND_RIGHT];
                vm.channelMappings[6].audioChannelId =
                    vm.channelAssociativeObject[vm.CHANNEL_CONST.MAPPING_STEREO_LEFT];
                vm.channelMappings[7].audioChannelId =
                    vm.channelAssociativeObject[vm.CHANNEL_CONST.MAPPING_STEREO_RIGHT];
                break;
            case 10:
                vm.channelMappings[0].audioChannelId =
                    vm.channelAssociativeObject[vm.CHANNEL_CONST.MAPPING_ORG_FRONT_LEFT];
                vm.channelMappings[1].audioChannelId =
                    vm.channelAssociativeObject[vm.CHANNEL_CONST.MAPPING_ORG_FRONT_RIGHT];
                vm.channelMappings[2].audioChannelId =
                    vm.channelAssociativeObject[vm.CHANNEL_CONST.MAPPING_ORG_CENTER];
                vm.channelMappings[3].audioChannelId =
                    vm.channelAssociativeObject[vm.CHANNEL_CONST.MAPPING_ORG_LFE];
                vm.channelMappings[4].audioChannelId =
                    vm.channelAssociativeObject[vm.CHANNEL_CONST.MAPPING_ORG_SURROUND_LEFT];
                vm.channelMappings[5].audioChannelId =
                    vm.channelAssociativeObject[vm.CHANNEL_CONST.MAPPING_ORG_SURROUND_RIGHT];
                vm.channelMappings[6].audioChannelId =
                    vm.channelAssociativeObject[vm.CHANNEL_CONST.MAPPING_STEREO_LEFT];
                vm.channelMappings[7].audioChannelId =
                    vm.channelAssociativeObject[vm.CHANNEL_CONST.MAPPING_STEREO_RIGHT];
                vm.channelMappings[8].audioChannelId =
                    vm.channelAssociativeObject[vm.CHANNEL_CONST.MAPPING_SAP_SILENCE];
                vm.channelMappings[9].audioChannelId =
                    vm.channelAssociativeObject[vm.CHANNEL_CONST.MAPPING_VDS_SILENCE];
                break;
            default:
                //Default to silence
                vm.channelMappings.forEach(function (mapping:any) {
                    mapping.audioChannelId =
                        vm.channelAssociativeObject[vm.CHANNEL_CONST.MAPPING_SILENCE];
                });
                break;
        }
    };

    /* INITIALIZATION */
    $onInit() {
        // Method-wide declarations
        let vm = this;

        //get the audio channels
        vm.$q.all([vm.AudioChannelResourceFactory.query().$promise]).then((dataResults:any) => {
            vm.audioChannels = dataResults[0];
            vm.audioChannels.forEach(function (channel:any) {
                vm.channelAssociativeObject[channel.name] = channel.id;
            });

            // on load, we need to do the gymnastics to determine whether mappings are already set, how many channels there should be,
            // add new ones if some are set but count has changed, and set defaults.
            vm._setUpChannels();
        });
    }
}
