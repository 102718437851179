/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2017 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */
//// CONTROLLER ////
import { ConfigureChannelMappingDialogController } from './dialogs/configureChannelMappingDialog-controller';

(function () {
  'use strict';

  angular.module('comcast.settings').controller('DefaultMediaPrefersDetailController', ['$scope', '$rootScope', '$state', '$stateParams', '$mdDialog', '$q', 'EnumService', 'MediaFormatResourceFactory', 'loginService', 'ReceiverResourceFactory', 'addelivery.event.constants', 'NotificationService', '$timeout', 'VideoFormatProfileResource', 'QcVendorResourceFactory', function ($scope, $rootScope, $state, $stateParams, $mdDialog, $q, enumService, MediaFormatResourceFactory, loginService, ReceiverResourceFactory, EventConstants, NotificationService, $timeout, VideoFormatProfileResource, QcVendorResourceFactory) {
    /* PRIVATE : DATA */

    /*
     * This is the `vm` object. It is a direct reference to the controller
     * which acts as our 'view-model' in angular. It also limits our need
     * to be accessing $scope directly. */

    /*jshint validthis:true*/
    var vm = this;
    /* BINDABLE : DATA */

    vm.sessionData = loginService.getSessionData();
    vm.accountType = loginService.getSessionData().accountType.replace(/_/g, '');
    vm.sdMediaFormatDescription = '';
    vm.hdMediaFormatDescription = '';
    vm.misconfiguredSdDigital = false;
    vm.misconfiguredHdDigital = false;
    vm.vastVersions = [{
      label: 'Not Enabled',
      value: null
    }, {
      label: '3.0',
      value: '3.0'
    }];
    /* BINDABLE : METHODS*/

    vm.convertToInt = convertToInt;
    vm.maxPeakSort = maxPeakSort;
    vm.openTabContent = openTabContent;
    vm.resetSdMediaFormat = resetSdMediaFormat;
    vm.configureChannelMapping = configureChannelMapping;
    vm.dirtyProxySelects = dirtyProxySelects;
    vm.disableSave = disableSave;
    vm.saveDefaultMediaPrefsDetail = saveDefaultMediaPrefsDetail;
    vm.resetDefaultMediaPrefsDetail = resetDefaultMediaPrefsDetail;
    /* EVENTS */
    //Register any event listeners

    $scope.$on('$destroy', function () {
      $('.sidebar').removeClass('hidden');
    });
    /* INITIALIZATION */
    //Call controller initialization method here

    _init();
    /* IMPLEMENTATION : BINDABLE */


    function convertToInt(value) {
      return parseInt(value, 10);
    }

    function maxPeakSort(value) {
      return parseInt(value.value, 10);
    }

    function resetSdMediaFormat(value) {
      if (value == 'sdFormat') {
        // Retrieve the SD Media Format object with the selected id
        var mediaFormat = vm.mediaFormats.filter(function (filterFormat) {
          return filterFormat.id == vm.receiver.sdMediaFormatId;
        });

        if (mediaFormat[0]) {
          vm.sdMediaFormatDescription = mediaFormat[0].description ? mediaFormat[0].description : '';
        } else {
          vm.sdMediaFormatDescription = '';
        }

        if (vm.receiver.sdMediaFormatId == '' || vm.receiver.sdMediaFormatId == null) {
          vm.receiver.SDVideoFormatProfile.leadingBlackFrameCount = null;
          vm.receiver.SDVideoFormatProfile.postSlateBlackFrameCount = null;
          vm.receiver.SDVideoFormatProfile.slateFrameCount = null;
          vm.receiver.SDVideoFormatProfile.trailingBlackFrameCount = null;
        }
      }

      if (value == 'sdProxyFormat') {
        if (vm.receiver.rcvrSdProxyFormatId == '' || vm.receiver.rcvrSdProxyFormatId == null) {
          vm.receiver.SDProxyFormatProfile.leadingBlackFrameCount = null;
          vm.receiver.SDProxyFormatProfile.postSlateBlackFrameCount = null;
          vm.receiver.SDProxyFormatProfile.slateFrameCount = null;
          vm.receiver.SDProxyFormatProfile.trailingBlackFrameCount = null;
        }
      }

      if (value == 'hdFormat') {
        // Retrieve the HD Media Format object with the selected id
        var _mediaFormat = vm.mediaFormats.filter(function (filterFormat) {
          return filterFormat.id == vm.receiver.hdMediaFormatId;
        });

        if (_mediaFormat[0]) {
          vm.hdMediaFormatDescription = _mediaFormat[0].description ? _mediaFormat[0].description : '';
        } else {
          vm.hdMediaFormatDescription = '';
        }

        if (vm.receiver.hdMediaFormatId == '' || vm.receiver.hdMediaFormatId == null) {
          vm.receiver.HDVideoFormatProfile.leadingBlackFrameCount = null;
          vm.receiver.HDVideoFormatProfile.postSlateBlackFrameCount = null;
          vm.receiver.HDVideoFormatProfile.slateFrameCount = null;
          vm.receiver.HDVideoFormatProfile.trailingBlackFrameCount = null;
        }
      }

      if (value == 'hdProxyFormat') {
        if (vm.receiver.rcvrHdProxyFormatId == '' || vm.receiver.rcvrHdProxyFormatId == null) {
          vm.receiver.HDProxyFormatProfile.leadingBlackFrameCount = null;
          vm.receiver.HDProxyFormatProfile.postSlateBlackFrameCount = null;
          vm.receiver.HDProxyFormatProfile.slateFrameCount = null;
          vm.receiver.HDProxyFormatProfile.trailingBlackFrameCount = null;
        }
      }
    }

    function openTabContent(tabName) {
      $('.tab-pane').removeClass('active');
      $('.tab-button').removeClass('active');
      $('#' + tabName + 'TabContent').addClass('active');
      $('#' + tabName + 'TabButton').addClass('active');
    }

    function configureChannelMapping(config, mediaFormatId, editable) {
      // need to look up the selected media format because the object may not reflect a new id chosen by the user.
      var mediaFormat = vm.mediaFormats.filter(function (format) {
        return format.id == mediaFormatId;
      });

      if (mediaFormat.length == 1 && editable) {
        var selectedFormat = mediaFormat[0];
        $mdDialog.show({
          controller: ConfigureChannelMappingDialogController,
          controllerAs: 'vm',
          template: require('./dialogs/configureChannelMappingDialog-template.html'),
          parent: angular.element(document.body),
          clickOutsideToClose: true,
          fullscreen: true,
          // For small screens only
          locals: {
            config: config,
            format: selectedFormat
          }
        }).then(function submitted(saved) {
          if (saved) {
            $scope.defaultmediaprefersdetailForm.$setDirty();
          }
        }, function cancelled() {// Do nothing, the user cancelled
        });
      }
    }

    function dirtyProxySelects() {
      if (vm.receiver.proxyFlag && vm.receiver.rcvrSdProxyFormatId === '') {
        vm.receiver.rcvrSdProxyFormatId = undefined;
      }

      if (vm.receiver.proxyFlag && vm.receiver.rcvrHdProxyFormatId === '') {
        vm.receiver.rcvrHdProxyFormatId = undefined;
      }
    }

    function disableSave() {
      if ($scope.defaultmediaprefersdetailForm) {
        if ($scope.defaultmediaprefersdetailForm.$dirty && $scope.defaultmediaprefersdetailForm.$valid) {
          return false;
        }

        return true;
      }
    }
    /* IMPLEMENTATION : PRIVATE */
    // All private methods should start with '_' in order to distinguish them


    function saveDefaultMediaPrefsDetail() {
      if (vm.accountType === 'QCVENDOR') {
        QcVendorResourceFactory.save({
          id: vm.sessionData.accountId
        }, vm.receiver, function success(receiver) {
          NotificationService.showNotificationToast('Saved default media preferences successfully');
          $scope.defaultmediaprefersdetailForm.$setPristine();
          vm.originalReceiver = angular.copy(receiver.data);
          vm.receiver = angular.copy(vm.originalReceiver);
        }, function failure(err) {
          NotificationService.showNotificationToast('Unable to save the default media preferences with the given information', err.data);
        });
      } else {
        if (vm.receiver.spotMetadataXformId == '') {
          vm.receiver.spotMetadataXformId = null;
        }

        if (vm.receiver.sdMediaFormatId == '') {
          vm.receiver.sdMediaFormatId = null;
        }

        if (vm.receiver.rcvrSdProxyFormatId == '') {
          vm.receiver.rcvrSdProxyFormatId = null;
        }

        if (vm.receiver.hdMediaFormatId == '') {
          vm.receiver.hdMediaFormatId = null;
        }

        if (vm.receiver.rcvrHdProxyFormatId == '') {
          vm.receiver.rcvrHdProxyFormatId = null;
        }

        ReceiverResourceFactory.update({
          id: vm.receiver.id
        }, vm.receiver, function (receiver) {
          NotificationService.showNotificationToast('Saved default media preferences successfully');
          $scope.defaultmediaprefersdetailForm.$setPristine();
          vm.originalReceiver = angular.copy(receiver.data);
          vm.receiver = angular.copy(receiver.data);
        }, function failure(err) {
          NotificationService.showNotificationToast('Unable to save the default media preferences with the given information', err.data);
        });
      }
    }

    function resetDefaultMediaPrefsDetail() {
      $scope.defaultmediaprefersdetailForm.$setPristine();
      vm.receiver = angular.copy(vm.originalReceiver);

      _resetMediaFormatDescription();
    }

    function _resetMediaFormatDescription() {
      var sdMediaFormat = vm.mediaFormats.filter(function (mediaFormat) {
        return mediaFormat.id == vm.originalReceiver.sdMediaFormatId;
      });

      if (sdMediaFormat[0]) {
        vm.sdMediaFormatDescription = sdMediaFormat[0].description ? sdMediaFormat[0].description : '';
      }

      var hdMediaFormat = vm.mediaFormats.filter(function (mediaFormat) {
        return mediaFormat.id == vm.originalReceiver.hdMediaFormatId;
      });

      if (hdMediaFormat[0]) {
        vm.hdMediaFormatDescription = hdMediaFormat[0].description ? hdMediaFormat[0].description : '';
      }
    }
    /**
     * Initialization for this controller.
     */


    function _init() {
      $('.sidebar').addClass('hidden');
      var userPromise;

      if (vm.accountType === 'QCVENDOR') {
        userPromise = QcVendorResourceFactory.get({
          id: vm.sessionData.accountId
        }).$promise;
      } else {
        userPromise = ReceiverResourceFactory.get({
          id: vm.sessionData.accountId
        }).$promise;
      }

      $q.all([enumService.getEnum('Spot', 'spotMetadataTransform'), enumService.getEnum('AdDeliverySecurityAccount', 'filenamePattern'), enumService.getEnum('AdDeliverySecurityAccount', 'xmlFilenamePattern'), enumService.getEnum('ReceiverVideoFormatProfile', 'maxPeak'), enumService.getEnum('ReceiverVideoFormatProfile', 'targetLoudness'), MediaFormatResourceFactory.query().$promise, userPromise]).then(function (dataResults) {
        vm.spotMetadataTransformList = dataResults[0];
        vm.fileNamePatterns = dataResults[1];
        vm.xmlFilenamePatterns = dataResults[2];
        vm.maxPeakPatterns = dataResults[3];
        vm.targetLoudnessPatterns = dataResults[4];
        vm.mediaFormats = dataResults[5].data;
        vm.receiver = angular.copy(dataResults[6]);
        vm.originalReceiver = angular.copy(vm.receiver);

        if (vm.accountType === 'QCVENDOR') {
          vm.fileNamePatterns = vm.fileNamePatterns.filter(function (item) {
            if (item.label === 'SpotID.extension' || item.label === 'ISCI.extension') {
              return item;
            } else {
              return false;
            }
          });
        }

        $timeout(function () {
          // Why is this necessary? I don't like it. I wish I could understand
          vm.receiver = angular.copy(vm.originalReceiver);
          $scope.defaultmediaprefersdetailForm.$setPristine();
        });
        var sdMediaFormat = vm.mediaFormats.filter(function (mediaFormat) {
          return mediaFormat.id == vm.receiver.sdMediaFormatId;
        });

        if (sdMediaFormat[0]) {
          vm.sdMediaFormatDescription = sdMediaFormat[0].description ? sdMediaFormat[0].description : '';
          vm.misconfiguredSdDigital = sdMediaFormat[0].isDigital && (!vm.receiver.vastVersion || vm.receiver.vastVersion === null);
        }

        var hdMediaFormat = vm.mediaFormats.filter(function (mediaFormat) {
          return mediaFormat.id == vm.receiver.hdMediaFormatId;
        });

        if (hdMediaFormat[0]) {
          vm.hdMediaFormatDescription = hdMediaFormat[0].description ? hdMediaFormat[0].description : '';
          vm.misconfiguredHdDigital = hdMediaFormat[0].isDigital && (!vm.receiver.vastVersion || vm.receiver.vastVersion === null);
        }

        if (vm.misconfiguredSdDigital || vm.misconfiguredHdDigital) {
          var formatText = '';

          if (vm.misconfiguredSdDigital) {
            formatText += 'SD';
          }

          if (vm.misconfiguredSdDigital && vm.misconfiguredHdDigital) {
            formatText += ' and ';
          }

          if (vm.misconfiguredHdDigital) {
            formatText += 'HD';
          }

          var alertText = 'Your default ' + formatText + ' media preferences are misconfigured with a digital media format that is not allowed.' + ' It has been reset to prevent errors.';
          $mdDialog.show($mdDialog.alert({
            title: 'Notice',
            textContent: alertText,
            ok: 'OK'
          })).then(function () {
            if (vm.misconfiguredSdDigital) {
              vm.receiver.sdMediaFormatId = null;
              vm.receiver.SDVideoFormatProfile.channelMappingFlag = false;
              $scope.defaultmediaprefersdetailForm.$setDirty();
            }

            if (vm.misconfiguredHdDigital) {
              vm.receiver.hdMediaFormatId = null;
              vm.receiver.HDVideoFormatProfile.channelMappingFlag = false;
              $scope.defaultmediaprefersdetailForm.$setDirty();
            }
          });
        }
      });
      $timeout(function () {
        $rootScope.defaultmediaprefersdetailForm = $scope.defaultmediaprefersdetailForm;
      });
    }
  }]);
})();